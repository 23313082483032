<template>
  <fragment>
    <b-row>
      <b-col cols="12" sm="12" md="12" lg="6">
        <CardForm :backButton="true" :title="title" :subTitle="subTitle">
          <template #toolbar>
            <b-button-group class="mr-2" v-show="$route.name != route.form">
              <b-button variant="outline-danger" @click="handleDelete">
                Hapus
              </b-button>
            </b-button-group>
            <b-button variant="primary" @click="handleSubmit">
              {{ textButton }}
            </b-button>
          </template>
          <template #form>
            <b-form @submit.stop.prevent="handleSubmit">
              <div class="card-body">
                <b-row>
                  <b-col lg="6">
                    <InputText
                      label="Nomor Transaksi"
                      type="text"
                      v-model="form.transactionNumber"
                      disabled
                      :useHorizontal="false"
                    />
                  </b-col>
                  <b-col lg="6">
                    <InputText
                      label="Tanggal Transaksi"
                      type="date"
                      v-model="form.transactionDate"
                      :max="dateFormat(getDate(), `YYYY-MM-DD`)"
                      :v="$v.form.transactionDate"
                      :useHorizontal="false"
                    />
                  </b-col>
                </b-row>
                <InputText
                  label="Pengeluarannya untuk beli/bayar apa ?"
                  type="text"
                  v-model="form.expense"
                  :v="$v.form.expense"
                  :useHorizontal="false"
                />
                <b-row>
                  <b-col lg="6">
                    <RadioGroup
                      v-model="form.expenseGroup"
                      label="Kategori Pengeluaran"
                      :options="options.expenseGroup"
                      :useHorizontal="false"
                    />
                  </b-col>
                  <b-col lg="6">
                    <InputText
                      label="Kuantiti"
                      type="number"
                      v-model="form.quantity"
                      :v="$v.form.quantity"
                      :useHorizontal="false"
                    />
                  </b-col>
                  <b-col lg="6">
                    <InputMoney
                      label="Harga/Biaya Satuan"
                      v-model="form.amount"
                      :v="$v.form.amount"
                      :useHorizontal="false"
                    />
                  </b-col>
                  <b-col lg="6">
                    <InputMoney
                      label="Total Pengeluaran"
                      v-model="grandTotal"
                      disabled
                      :useHorizontal="false"
                    />
                  </b-col>
                </b-row>
              </div>
            </b-form>
          </template>
        </CardForm>
      </b-col>
    </b-row>
  </fragment>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import { expenseGroup } from "@/core/datasource/options";
import { getDate, dateFormat } from "@/core/utils";

export default {
  data: () => ({
    title: "Pengeluaran",
    route: {
      form: "pengeluaranCreate",
      table: "pengeluaran",
    },
    form: {
      transactionNumber: "Auto Generate",
      transactionDate: getDate(),
      expense: null,
      expenseGroup: 0,
      quantity: 1,
      amount: 0,
    },
    options: {
      expenseGroup,
    },
  }),
  validations: {
    form: {
      transactionDate: { required },
      expense: { required },
      quantity: { required },
      amount: { required },
    },
  },
  computed: {
    ...mapGetters(["store"]),
    subTitle() {
      const self = this;
      return self.$route.name != self.route.form
        ? "Ubah pengeluaran"
        : "Buat pengeluaran baru";
    },
    textButton() {
      const self = this;
      return self.$route.name != self.route.form
        ? "Simpan Perubahan"
        : "Simpan";
    },
    grandTotal() {
      return this.form.quantity * this.form.amount;
    },
  },
  created() {
    const self = this;

    if (self.$route.name != self.route.form) {
      self.getById();
    }
  },
  methods: {
    getDate,
    dateFormat,
    getById() {
      const self = this;

      let loader = self.$loading.show();
      self.$store
        .dispatch("apis/get", {
          url: `/transaction/${self.store.id}/expense/${self.$route.params.id}`,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });

            self.$router.push({ name: self.route.table });
          } else {
            self.form = {
              transactionNumber: response.data.transactionNumber,
              transactionDate: dateFormat(
                response.data.transactionDate,
                "YYYY-MM-DD"
              ),
              expense: response.data.expense,
              expenseGroup: response.data.expenseGroup?.id,
              quantity: response.data.quantity,
              amount: response.data.amount,
            };
          }
        })
        .finally(() => loader.hide());
    },
    handleSubmit() {
      const self = this;

      self.$v.form.$touch();
      if (self.$v.form.$pending || self.$v.form.$error) return;

      let _confirmText = "",
        _okText = "",
        _action = "",
        _url = "";

      if (self.$route.name == self.route.form) {
        _confirmText = "Kamu yakin mau menyimpan store ini ?";
        _okText = "Ya, Simpan";
        _action = "apis/post";
        _url = `/transaction/${self.store.id}/expense`;
      } else {
        _confirmText = "Kamu yakin mau mengubah store ini ?";
        _okText = "Ya, Ubah";
        _action = "apis/put";
        _url = `/transaction/${self.store.id}/expense/${self.$route.params.id}`;
      }

      self.$dialog
        .confirm(_confirmText, {
          okText: _okText,
          cancelText: "Ga jadi",
          loader: true,
        })
        .then((dialog) => {
          let payload = {
            transactionDate: self.form.transactionDate,
            expense: self.form.expense,
            expenseGroup: self.form.expenseGroup,
            quantity: self.form.quantity,
            amount: self.form.amount,
          };

          self.$store
            .dispatch(_action, {
              url: _url,
              params: payload,
            })
            .then((response) => {
              if (response.error) {
                self.$message.error({
                  zIndex: 10000,
                  message: response.message,
                });
              } else {
                self.$message.success({
                  zIndex: 10000,
                  message: response.message,
                });

                self.$router.go(-1);
              }
            })
            .finally(() => dialog.close());
        });
    },
    handleDelete() {
      const self = this;

      self.$dialog
        .confirm("Kamu yakin mau menghapus store ini ?", {
          okText: "Ya, Hapus",
          cancelText: "Ga jadi",
          loader: true,
        })
        .then((dialog) => {
          self.$store
            .dispatch("apis/remove", {
              url: `/transaction/${self.store.id}/expense/${self.$route.params.id}`,
            })
            .then((response) => {
              if (response.error) {
                self.$message.error({
                  zIndex: 10000,
                  message: response.message,
                });
              } else {
                self.$message.success({
                  zIndex: 10000,
                  message: response.message,
                });

                self.$router.go(-1);
              }
            })
            .finally(() => dialog.close());
        });
    },
  },
};
</script>
